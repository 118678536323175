import React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import { images } from "../../constants";

const ServiceCard = ({ urlImage, title, description, children }) => {
  return (
    <div style={{ margin: "5%", height: "100%" , paddingBottom: 60,    }}>
      <Card
        sx={{
          height: "100%",
          maxWidth: 385,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          elevation: 0,
          margin: "auto",
          paddingBottom: 0,       }}
      >
        <CardHeader
          title={title}
          sx={{
            textAlign: "center",
            paddingBottom: 2,
          }}
        />

        <CardContent sx={{ flexGrow: 1 }}>
          <Box
            bgcolor="black"
            sx={{
              borderRadius: 1,
              overflow: "hidden",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              src={images[urlImage]}
              alt="design"
              style={{
                width: "100%",
                height: "auto",
                objectFit: "cover",
              }}
            />
          </Box>
        </CardContent>

        <CardContent>
          <Typography paragraph align="center">
            {description}
          </Typography>
        </CardContent>

        {/* Collapsible content aligned to the bottom */}
        <Collapse in={true} timeout="auto" unmountOnExit>
          <CardContent
            sx={{
              marginTop: "auto", // Pushes this CardContent to the bottom of the Card
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end", // Ensures children content stays at the bottom
            }}
          >
            {children}
          </CardContent>
        </Collapse>
      </Card>
    </div>
  );
};

export default ServiceCard;
