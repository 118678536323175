import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import ServiceButtonCard from "../ServiceButtonCard";
import ServiceModal3 from "./ServiceModal3";

const Children3 = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const handleOpen = (serviceOne) => {
    setShowModalService(serviceOne);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);


  const [showModalService, setShowModalService] = useState(true);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",

      }}
    >
      <ServiceButtonCard
        text={t("service_3_subtitle_1")}
        onClick={() => handleOpen(true)}
      />
      <ServiceButtonCard
        text={t("service_3_subtitle_2")}
        onClick={() => handleOpen(false)}
      />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
          <ServiceModal3 showContent={showModalService} setOpen={setOpen} />
      </Modal>
    </Box>
  );
};
export default Children3;
